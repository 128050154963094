// import store from "@/store"

let resizeListenerAdded = false
let documentTouchListenerAdded = false
let store
let $scrollingEl
let storedScrollPos

let initialClientY = 0
let initialClientX = 0
const lockedElements = []

let pushdownListener

// https://github.com/tuax/tua-body-scroll-lock/blob/master/src/utils.ts
const detectOS = ua => {
  ua = ua || navigator.userAgent
  const ipad = /(iPad).*OS\s([\d_]+)/.test(ua)
  const iphone = !ipad && /(iPhone\sOS)\s([\d_]+)/.test(ua)
  const android = /(Android);?[\s/]+([\d.]+)?/.test(ua)
  const ios = iphone || ipad

  return { ios, android }
}

const preventDefault = event => {
  if (!event.cancelable) return
  event.preventDefault()
}

const handleScroll = (event, targetElement) => {
  if (targetElement) {
    const {
      scrollTop,
      scrollLeft,
      scrollWidth,
      scrollHeight,
      clientWidth,
      clientHeight
    } = targetElement
    const clientX = event.targetTouches[0].clientX - initialClientX
    const clientY = event.targetTouches[0].clientY - initialClientY
    const isVertical = Math.abs(clientY) > Math.abs(clientX)

    const isAtTop = clientY > 0 && scrollTop === 0
    const isAtLeft = clientX > 0 && scrollLeft === 0
    const isAtRight = clientX < 0 && scrollLeft + clientWidth + 1 >= scrollWidth
    const isAtBottom =
      clientY < 0 && scrollTop + clientHeight + 1 >= scrollHeight

    if (
      (isVertical && (isAtTop || isAtBottom)) ||
      (!isVertical && (isAtLeft || isAtRight))
    ) {
      return preventDefault(event)
    }
  }

  event.stopPropagation()
  // return true
}

const lockScroll = (targetElement, callback) => {
  const $html = document.documentElement

  if (detectOS().ios || detectOS().android) {
    $scrollingEl =
      $scrollingEl || document.getElementById("scroll-lock-content")
    storedScrollPos = window.scrollY.toFixed()

    // Adjust position of scrolling content ('.bleach-site__store')
    $scrollingEl.style.transform = `translateY(-${storedScrollPos}px)`

    store.commit("scroll/SET_AUTOSCROLL", true)

    const onScroll = function() {
      if (Math.abs(window.scrollY.toFixed()) === 0) {
        window.removeEventListener("scroll", onScroll)
        store.commit("scroll/SET_AUTOSCROLL", false)
        $html.style.overflow = "hidden"
        if (callback) callback()
      }
    }

    // Enable scrolling on target element
    if (targetElement) {
      const elementArray = Array.isArray(targetElement)
        ? targetElement
        : [targetElement]

      elementArray.forEach(element => {
        if (element && lockedElements.indexOf(element) === -1) {
          element.ontouchstart = event => {
            initialClientY = event.targetTouches[0].clientY
            initialClientX = event.targetTouches[0].clientX
          }

          element.ontouchmove = event => {
            if (event.targetTouches.length !== 1) return

            handleScroll(event, element)
          }

          lockedElements.push(element)
        }
      })
    }

    // Lock out all touch events
    if (!documentTouchListenerAdded) {
      document.addEventListener("touchmove", preventDefault, { passive: false })
      documentTouchListenerAdded = true
    }

    window.scrollTo(0, 0)
    if (window.scrollY > 0) {
      window.addEventListener("scroll", onScroll)
      window.scrollTo(0, 0)
    } else {
      onScroll()
    }
  } else {
    $html.style.overflow = "hidden"
  }
}

const handlePushdownScrollEvents = (
  targetElement,
  navOffset = 0
) => touchEvent => {
  const { top } = targetElement.getBoundingClientRect()

  const viewportHeight = visualViewport.height

  const clientY = touchEvent.targetTouches[0].clientY - initialClientY

  let isAtTop
  let isAtBottom

  if (detectOS().ios) {
    // iOS (16) updates the boundingclientRect relative to the visualViewport
    isAtTop = clientY > 0 && Math.abs(Math.round(top)) <= navOffset
    isAtBottom =
      clientY < 0 &&
      Math.abs(Math.round(top)) + navOffset + Math.round(viewportHeight) >=
        window.innerHeight
  } else if (detectOS().android) {
    // Android GC does not
    isAtTop = clientY > 0 && Math.round(visualViewport.offsetTop) <= 0
    isAtBottom =
      clientY < 0 &&
      viewportHeight + visualViewport.offsetTop >= window.innerHeight
  }

  if (isAtTop || isAtBottom) {
    // prevent scroll up/down if extreme of page is visible
    return preventDefault(touchEvent)
  }
}

const pushdownLock = (targetElement, navOffset) => {
  pushdownListener = handlePushdownScrollEvents(targetElement, navOffset)
  // capture scroll events on the targetElement (the pushdown)
  if (targetElement && lockedElements.indexOf(targetElement) === -1) {
    targetElement.ontouchstart = event => {
      initialClientY = event.targetTouches[0].clientY
      initialClientX = event.targetTouches[0].clientX
    }

    targetElement.ontouchmove = event => {
      if (event.targetTouches.length !== 1) return

      pushdownListener(event)
    }

    lockedElements.push(targetElement)
  }
}

const pushdownUnlock = targetElement => {
  if (targetElement) {
    const index = lockedElements.indexOf(targetElement)
    if (index !== -1) {
      targetElement.ontouchmove = null
      targetElement.ontouchstart = null
      lockedElements.splice(index, 1)
    }
  }
}

const unlockScroll = (targetElement, callback) => {
  const $html = document.documentElement

  if (detectOS().ios || detectOS().android) {
    $html.style.overflow = "visible"
    $scrollingEl.style.transform = `unset`

    store.commit("scroll/SET_AUTOSCROLL", true)

    const onScroll = function() {
      if (window.scrollY.toFixed() === storedScrollPos) {
        window.removeEventListener("scroll", onScroll)
        store.commit("scroll/SET_AUTOSCROLL", false)
        if (callback) callback()
      }
    }

    if (targetElement) {
      const elementArray = Array.isArray(targetElement)
        ? targetElement
        : [targetElement]

      elementArray.forEach(element => {
        const index = lockedElements.indexOf(element)

        if (index !== -1) {
          element.ontouchmove = null
          element.ontouchstart = null
          lockedElements.splice(index, 1)
        }
      })
    }

    if (documentTouchListenerAdded) {
      document.removeEventListener("touchmove", preventDefault, {
        passive: false
      })
      documentTouchListenerAdded = false
    }

    if (window.scrollY !== storedScrollPos) {
      window.addEventListener("scroll", onScroll)
      window.scrollTo({ top: storedScrollPos })
    } else {
      onScroll()
    }
  } else {
    $html.style.overflow = "visible"
  }
}

const init = _store => {
  store = _store
  addResizeListener()
  handleViewportResize()
}

function addResizeListener() {
  if (!resizeListenerAdded) {
    window.visualViewport.addEventListener("resize", () =>
      handleViewportResize()
    )
    resizeListenerAdded = true
  }
}

function handleViewportResize() {
  const $html = document.documentElement
  $html.style.height = `${visualViewport.height}px`
  store.commit("scroll/SET_VIEWPORT_HEIGHT", visualViewport.height)
}

export default { init }
export { lockScroll, unlockScroll, pushdownLock, pushdownUnlock }
