// CRIBBED SHAMELESSLY FROM SE
// https://stackoverflow.com/a/42057148
export default {
  ASSERT: 1,
  ERROR: 2,
  WARN: 3,
  INFO: 4,
  DEBUG: 5,
  VERBOSE: 6,
  set level(level) {
    if (level >= this.ASSERT) this.assert = console.assert.bind(window.console)
    else this.assert = function() {}
    if (level >= this.ERROR) this.error = console.error.bind(window.console)
    else this.error = function() {}
    if (level >= this.WARN) this.warn = console.warn.bind(window.console)
    else this.warn = function() {}
    if (level >= this.INFO) this.info = console.info.bind(window.console)
    else this.info = function() {}
    if (level >= this.DEBUG) this.debug = console.debug.bind(window.console)
    else this.debug = function() {}
    if (level >= this.VERBOSE) {
      this.log = console.log.bind(window.console)
      this.system = console.log.bind(
        window.console,
        "%c %s",
        "background: #222; color: #ddd"
      )
    } else {
      this.log = function() {}
      this.system = function() {}
    }
    this.loggingLevel = level
  },
  get level() {
    return this.loggingLevel
  }
}
