// import Auth from "@aws-amplify/auth"

// https://github.com/aws-amplify/amplify-js/issues/639
class AuthHelper {
  static async refreshSessionPromise(cognitoUser, refreshToken) {
    const AuthenticatedUser = await new Promise((resolve, reject) => {
      return cognitoUser.refreshSession(refreshToken, async (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    })

    return AuthenticatedUser
  }

  // static async refreshCurrentSession() {
  //   const session = await Auth.currentSession()
  //   return this.refreshSessionPromise(session.getRefreshToken())
  // }
}

export default AuthHelper
